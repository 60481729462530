import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js';
import { publicRoutes, publicPaths } from './publicRoutes.js';

const DashboardError = () => import('../components/error/DashboardError.vue')
const AddProperty = () => import('../components/forms/properties/AddProperty.vue')
const EditProperty = () => import('../components/forms/properties/EditProperty.vue')
const AddBlog = () => import('../components/forms/blogs/AddBlog.vue')
const EditBlog = () => import('../components/forms/blogs/EditBlog.vue')
const OverviewComponent = () => import('../components/admin/overview/DashBoard.vue')
const PartnerOverview = () => import('../components/partner/overview/DashBoard.vue')
const PropertiesDashboard = () => import('../components/admin/properties/DashBoard.vue')
const BlogsDashboard = () => import('../components/admin/blogs/DashBoard.vue')
const ScheduleDashboard = () => import('../components/admin/schedule/DashBoard.vue')
const MembersDashboard = () => import('../components/admin/members/DashBoard.vue')
const MemberDialog = () => import('../components/admin/members/MemberDialog.vue')
const AccountOverview = () => import('../components/client/overview/DashBoard.vue')
const InboxComponent = () => import('../components/client/inbox/DashBoard.vue')
const FavouritesComponent = () => import('../components/client/favourites/DashBoard.vue')
const OrderComponent = () => import('../components/client/orders/DashBoard.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    children: [...publicRoutes]
  },
  {
    path: '/Partner',
    component: () => import('../views/PartnerView.vue'),
    children: [
      { path: '/', redirect: '/Partner/dashboard' },
      { name:'Overview', path: '/Partner/dashboard', component: PartnerOverview},
      { name:'My Property', path: '/Partner/properties', component: () => import('../components/partner/properties/DashBoard.vue'),
        children: [
          { path: '/Partner/properties', redirect: '/Partner/properties/pending' },
          { name:'Pending Properties', path: '/Partner/properties/pending', component: () => import('../components/partner/properties/PendingProperties.vue')},
          { name:'Selling Properties', path: '/Partner/properties/selling', component: () => import('../components/partner/properties/SellingProperties.vue')},
          { name:'Leasing Properties', path: '/Partner/properties/leasing', component: () => import('../components/partner/properties/LeasingProperties.vue')},
          { name:'History', path: '/Partner/properties/history', component: () => import('../components/partner/properties/PropertiesHistory.vue')},
        ]
      },
      // { name:'Blogs', path: '/Partner/blogs', component: () => import('../components/partner/blogs/DashBoard.vue'),
      //   children: [
      //     { path: '/Partner/blogs', redirect: '/Partner/blogs/pending' },
      //     { name:'Pending Blogs', path: '/Partner/blogs/pending', component: () => import('../components/partner/blogs/PendingBlogs.vue')},
      //     { name:'Existing Blogs', path: '/Partner/blogs/existing', component: () => import('../components/partner/blogs/ExistingBlogs.vue')},
      //   ]
      //  },
      //  { name:'Add Blogs', path: '/Partner/blogs/add', component: () => import('../components/forms/blogs/AddBlogPartner.vue')},
       { name:'Add Property', path: '/Partner/properties/add', component: () => import('../components/forms/properties/AddPropertyPartner.vue') },
       { name:'Earnings', path: '/Partner/earnings', component: () => import('../components/partner/earnings/DashBoard.vue') },
       { name:'My Account', path: '/Partner/account', component: () => import('../components/partner/account/DashBoard.vue') },
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue'),
    children: [
      { path: '/', redirect: '/admin/dashboard' },
      { name:'Home', path: '/admin/dashboard', component: OverviewComponent},
      { name:'Property', path: '/admin/properties', component: PropertiesDashboard },
      { name:'Add Property', path: '/admin/properties/add', component: AddProperty },
      { name:'Edit Property', path: '/admin/properties/edit', component: EditProperty },
      { name:'Blogs', path: '/admin/blogs', component: BlogsDashboard },
      { name:'Add Blog', path: '/admin/blogs/add', component: AddBlog },
      { name:'Edit Blog', path: '/admin/blogs/edit', component: EditBlog },
      { name:'Schedule', path: '/admin/schedule', component: ScheduleDashboard },
      { name:'Staffs', path: '/admin/staffs', component: () => import('../components/admin/staff/StaffComponent.vue')  },
      { name:'Message', path: '/admin/chat', component: () => import('../components/admin/chat/DashBoard.vue') },
      { name:'Members', path: '/admin/members', component: MembersDashboard,
        children: [
          { path: '/admin/members', redirect: '/admin/members/incoming' },
          { name:'Incoming Members', path: '/admin/members/incoming', component: () => import('../components/admin/members/IncomingMembers.vue') },
          { name:'Clients', path: '/admin/members/clients', component: () => import('../components/admin/members/ClientsComponent.vue') },
          { name:'Partners', path: '/admin/members/partners', component: () => import('../components/admin/members/PartnersComponent.vue') },
          { name:'Agents', path: '/admin/members/agents', component: () => import('../components/admin/members/AgentsComponent.vue') },
        ]
      },
      { path: '/admin/member', component: MemberDialog },
      { path: '/admin/task-detail', component: () => import('../components/admin/schedule/TaskDialogMobile.vue') },
      { name:'Chat', path: '/admin/messages', component: () => import('../components/admin/chat/MessageDisplay.vue') },
      { name:'Settings', path: '/admin/info', component:  () => import('../components/admin/info/DashBoard.vue'),},
    ]
  },
  
  {
    path: '/agent',
    component: () => import(/* webpackChunkName: "about" */ '../views/AgentView.vue'),
    children: [
      { path: '/', redirect: '/agent/dashboard' },
      { name:'Home', path: '/agent/dashboard', component: () => import('../components/agent/overview/DashBoard.vue')},
      { name:'Property', path: '/agent/properties', component: () => import('../components/agent/properties/DashBoard.vue') },
      { name:'Add Property', path: '/agent/properties/add', component: () => import('../components/forms/properties/AddPropertyPartner.vue') },
      { name:'Schedule', path: '/agent/schedule', component: () => import('../components/agent/schedule/DashBoard.vue') },
      { name:'Message', path: '/agent/chat', component: () => import('../components/agent/chat/DashBoard.vue') },
      { name:'Chat', path: '/agent/messages', component: () => import('../components/agent/chat/MessageDisplay.vue') },
      { name:'Account Info', path: '/agent/info', component:  () => import('../components/agent/info/DashBoard.vue'),},
      { name: 'Error', path: '/agent/*', component: DashboardError },
    ]
  },
  {
    path: '/editor',
    component: () => import(/* webpackChunkName: "about" */ '../views/EditorView.vue'),
    children: [
      { path: '/', redirect: '/editor/dashboard' },
      { name:'Home', path: '/editor/dashboard', component: () => import('../components/editor/overview/DashBoard.vue')},
      { name:'Blog', path: '/editor/blogs', component: () => import('../components/editor/blogs/DashBoard.vue') },
      { name:'Add Blog', path: '/editor/blogs/add', component: () => import('../components/forms/blogs/AddBlogPartner.vue') },
      { name:'Edit Blog', path: '/editor/blogs/edit', component: () => import('../components/forms/blogs/EditBlog.vue') },
      { name:'Schedule', path: '/editor/schedule', component: () => import('../components/editor/schedule/DashBoard.vue') },
      { name:'Members', path: '/editor/members', component: () => import('../components/editor/members/DashBoard.vue'),
        children: [
          { path: '/editor/members', redirect: '/editor/members/agents' },
          { name:'Agents', path: '/editor/members/agents', component: () => import('../components/editor/members/AgentsComponent.vue') },
        ]
      },
      { name:'Message', path: '/editor/chat', component: () => import('../components/editor/chat/DashBoard.vue') },
      { name:'Chat', path: '/editor/messages', component: () => import('../components/editor/chat/MessageDisplay.vue') },
      { name:'Account Info', path: '/editor/info', component:  () => import('../components/editor/info/DashBoard.vue'),},
      { name: 'Error', path: '/editor/*', component: DashboardError },
    ]
  },
  {
    path: '/customersupport',
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomerSupport.vue'),
    children: [
      { path: '/', redirect: '/customersupport/dashboard' },
      { name:'Home', path: '/customersupport/dashboard', component: () => import('../components/customersupport/overview/DashBoard.vue')},
      { name:'Customer', path: '/customersupport/customer', component: () => import('../components/customersupport/customer/DashBoard.vue') },
      { name:'Schedule', path: '/customersupport/schedule', component: () => import('../components/customersupport/schedule/DashBoard.vue') },
      { name:'Members', path: '/customersupport/members', component: () => import('../components/customersupport/members/DashBoard.vue'),
        children: [
          { path: '/customersupport/members', redirect: '/customersupport/members/agents' },
          { name:'Agents', path: '/customersupport/members/agents', component: () => import('../components/customersupport/members/AgentsComponent.vue') },
        ]
      },
      { name:'Message', path: '/customersupport/chat', component: () => import('../components/customersupport/chat/DashBoard.vue') },
      { name:'Chat', path: '/customersupport/messages', component: () => import('../components/customersupport/chat/MessageDisplay.vue') },
      { name:'Account Info', path: '/customersupport/info', component:  () => import('../components/customersupport/info/DashBoard.vue'),},
      { name: 'Error', path: '/customersupport/*', component: DashboardError },
    ]
  },
  {
    path: '/hr',
    component: () => import(/* webpackChunkName: "about" */ '../views/HumanResource.vue'),
    children: [
      { path: '/', redirect: '/hr/dashboard' },
      { name:'Home', path: '/hr/dashboard', component: () => import('../components/hr/overview/DashBoard.vue')},
      { name:'Schedule', path: '/hr/schedule', component: () => import('../components/hr/schedule/DashBoard.vue') },
      { name:'Staffs', path: '/hr/staffs', component: DashboardError },
      { name:'Message', path: '/hr/chat', component: () => import('../components/hr/chat/DashBoard.vue') },
      { name:'Members', path: '/hr/members', component: () => import('../components/hr/members/DashBoard.vue') ,
        children: [
          { path: '/hr/members', redirect: '/hr/members/incoming' },
          { name:'Incoming Members', path: '/hr/members/incoming', component: () => import('../components/hr/members/IncomingMembers.vue') },
          { name:'Partners', path: '/hr/members/partners', component: () => import('../components/hr/members/PartnersComponent.vue') },
          { name:'Agents', path: '/hr/members/agents', component: () => import('../components/hr/members/AgentsComponent.vue') },
        ]
      },
      { path: '/hr/member', component: () => import('../components/hr/members/MemberDialog.vue') },
      { path: '/hr/task-detail', component: () => import('../components/hr/schedule/TaskDialogMobile.vue') },
      { name:'Chat', path: '/hr/messages', component: () => import('../components/hr/chat/MessageDisplay.vue') },
      { name:'Settings', path: '/hr/info', component:  () => import('../components/hr/info/DashBoard.vue'),},
    ]
  },
  {
    path: '/staff',
    component: () => import(/* webpackChunkName: "about" */ '../views/OtherView.vue'),
    children: [
      { path: '/', redirect: '/staff/dashboard' },
      { name:'Home', path: '/staff/dashboard', component: () => import('../components/other/overview/DashBoard.vue')},
      { name:'Schedule', path: '/staff/schedule', component: () => import('../components/other/schedule/DashBoard.vue') },
      { name:'Members', path: '/staff/members', component: () => import('../components/other/members/DashBoard.vue'),
        children: [
          { path: '/staff/members', redirect: '/staff/members/agents' },
          { name:'Agents', path: '/staff/members/agents', component: () => import('../components/other/members/AgentsComponent.vue') },
        ]
      },
      { name:'Message', path: '/staff/chat', component: () => import('../components/other/chat/DashBoard.vue') },
      { name:'Chat', path: '/staff/messages', component: () => import('../components/other/chat/MessageDisplay.vue') },
      { name:'Account Info', path: '/staff/info', component:  () => import('../components/other/info/DashBoard.vue'),},
      { name: 'Error', path: '/staff/*', component: DashboardError },
    ]
  },
  {
    path: '/client',
    component: () => import( '../views/ClientView.vue'),
    children: [
      { path: '/', redirect: '/client/dashboard' },
      { name:'Account Overview', path: '/client/dashboard', component: AccountOverview},
      { name:'Inbox', path: '/client/inbox', component: InboxComponent},
      { name:'Favourites', path: '/client/favourites', component: FavouritesComponent},
      { name:'Orders', path: '/client/orders', component: OrderComponent,
      children: [
        { path: '/client/orders', redirect: '/client/orders/completed' },
        { name:'Completed Orders', path: '/client/orders/completed', component: () => import('../components/client/orders/CompletedOrders.vue')},
        { name:'Pending Orders', path: '/client/orders/pending', component: () => import('../components/client/orders/PendingOrders.vue')},
      ]
      },
      { name:'Recently Viewed', path: '/client/history', component:  () => import('../components/client/history/DashBoard.vue'),},
      { name:'Account Info', path: '/client/info', component:  () => import('../components/client/info/DashBoard.vue'),},
      { name: 'Error', path: '/client/*', component: DashboardError },

    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
     return { x: 0, y: 0 }
   }
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = Object.values(publicPaths);
  const authRequired = !publicPages.includes(to.path);
  const adminPages = ['/admin', '/admin/properties/edit', '/admin/dashboard', '/admin/chat', '/admin/properties', '/admin/properties/add', '/admin/blogs', '/admin/blogs/edit', '/admin/schedule', '/admin/members', '/admin/member', '/admin/info', '/admin/messages', '/admin/*']
  const adminAuth = adminPages.includes(to.path);
  const agentPages = ['/agent', '/agent/chat', '/agent/dashboard', '/agent/properties', '/agent/properties/add', '/agent/schedule', '/agent/members','/agent/messages', '/agent/info', '/agent/*']
  const agentAuth = agentPages.includes(to.path);
  const editorPages = ['/editor', '/editor/chat', '/editor/dashboard', '/editor/schedule', '/editor/members','/editor/messages', '/editor/info', '/editor/blogs',  '/editor/blogs/add', '/editor/blogs/edit',  '/editor/*']
  const editorAuth = editorPages.includes(to.path);
  const customersupportPages = ['/customersupport', '/customersupport/customer', '/customersupport/chat', '/customersupport/dashboard', '/customersupport/schedule', '/customersupport/members','/customersupport/messages', '/customersupport/info', '/customersupport/*']
  const customersupportAuth = customersupportPages.includes(to.path);
  const otherPages = ['/staff', '/staff/chat', '/staff/dashboard', '/staff/schedule', '/staff/members','/staff/messages', '/staff/info', '/staff/*']
  const otherAuth = otherPages.includes(to.path);
  const partnerPages = ['/Partner', '/Partner/dashboard', '/Partner/properties', '/Partner/properties/add', '/Partner/blogs', '/Partner/blogs/pending', '/Partner/blogs/existing', '/Partner/schedule', '/Partner/*'];
  const partnerAuth = partnerPages.includes(to.path);
  const clientPages = ['/checkout', '/payment-status', '/client', '/client/dashboard', '/client/inbox', '/client/favourites', '/client/orders', '/client/info', '/client/*'];
  const clientAuth = clientPages.includes(to.path);

  const loggedIn = store.getters.isLoggedIn;
  // const isApproved = store.getters.checkApproval;
  const role = store.getters.getRole;
  const position = store.getters.getUser.position;

  if (to.path == '/recovery-successful' && from.path !== '/password-recovery') {
    return next('/login')
  }
  if (to.path == '/password-recovery') {
    if (from.path == '/login') {
      return next()
    }
    if (from.path == '/partners') {
      return next()
    }
    if (from.path !== '/login' || from.path !== '/partners') {
      return next('/error')
    }
  }

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  if (authRequired && adminAuth && role !== 'Admin') {
    return next('/dashboard')
  }
  if (authRequired && agentAuth ) {
    if (position == 'Agent') {
      next();
    }  else {
      return next('/dashboard')
    }
  }
  if (authRequired && editorAuth ) {
    if (position == 'Editor') {
      next();
    }  else {
      return next('/dashboard')
    }
  }
  if (authRequired && customersupportAuth ) {
    if (position == 'Customer Support') {
      next();
    }  else {
      return next('/dashboard')
    }
  }
  if (authRequired && otherAuth ) {
    if (position == 'Other') {
      next();
    }  else {
      return next('/dashboard')
    }
  }
  if (authRequired && clientAuth) {
    if (role == 'Client' ) {
      next();
    } else if (role == 'Staff' && position == 'Not Assigned') {
      next();
    } else {
      return next('/dashboard')
    }
  }
  if (authRequired && partnerAuth && role !== 'Partner') {
    return next('/dashboard')
  }

  next();
})
export default router
