const state = {
  trendingProperties: [],
};
const getters = {
  getTrendingProperties: state => {
      return state.trendingProperties;
  },
  getTrendingPropertiesLength: state => {
      return state.trendingProperties.length;
  },
};
const mutations = {
  'SET_TRENDING_PROPERTY' (state, data) {
      state.user.favourites.push(data);
  },
  'DELETE_TRENDING_PROPERTY' (state, element) {
      var arr = state.trendingProperties.trendingProperties;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == element ) {
          arr.splice(i, 1);
        }
      }
  },
};
const actions = {
  setTrendingProperty ({commit}, status) {
      commit('SET_TRENDING_PROPERTY', status);
  },
  deleteTendingPropery ({commit}, element) {
      commit('DELETE_TRENDING_PROPERTY', element);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
