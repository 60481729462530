<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script type="text/javascript">
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    
    async getUserDetail(id) {
      const docRef = doc(this.$db, "users", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        var data = docSnap.data();
        if (data.approved == true) {
          this.$store.dispatch('setUser', data);
          this.$store.dispatch('setStatus', true);
        } else if (data.approved == false && data.role == 'Staff'){
          this.$store.dispatch('setUser', data);
          this.$store.dispatch('setStatus', true);
        } else{
          this.$store.dispatch('setStatus', false);
        }
      } else {
          alert('Backend Error')
      }
    },
  },
  mounted() {
    //Check if user is Logged in
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.getUserDetail(user.uid)
      } else {
        // User is signed out
        this.$store.dispatch('setStatus', false);
        this.$store.dispatch('clearUser');
      }
    });
 
  }
};

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

$body-font-family: 'Poppins';

.v-application {
 font-family: $body-font-family, sans-serif !important;
}
</style>

