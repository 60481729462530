import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import store from './modules/store';
import { propertiesModule } from './modules/properties/index.js'
// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
  modules: {
    propertiesModule,
    auth,
    store,
  },
  plugins: [createPersistedState()]
});
