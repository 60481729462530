import { actions } from "./actions"
import { getters } from "./getters"
import { mutations } from "./mutations"

export const propertiesModule = {
    state: () => ({ 
        properties: [],
        property: [],
        searchStatus: false,
     }),
     getters,
     actions,
     mutations
  }
 