import {  collection, query, getDocs, where, orderBy } from "firebase/firestore";
import { propConverter } from "@/helpers/helperClasses.js";
import { db } from "@/main.js";

export const actions = {
    async getProperties ({commit}, payload) {
        commit('SET_SEARCH_STATUS', false);
        const properties = []
        var q = query(collection(db, "properties_detail"), where("approved", "==", true), orderBy("uploaded_at", "desc")).withConverter(propConverter); 
        if(payload.type == 'Sale'){
            q = query(collection(db, "properties_detail"), where("approved", "==", true), where("type", "==", "Sale"), orderBy("uploaded_at", "desc")).withConverter(propConverter);
        } else if(payload.type == 'Rent'){
            q = query(collection(db, "properties_detail"), where("approved", "==", true), where("type", "==", "Lease"), orderBy("uploaded_at", "desc")).withConverter(propConverter);
        }

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        properties.push(doc.data());
        });

        commit('SET_PROPERTIES', properties);
    },
    async searchProperties({commit}, payload) {
        var data = payload;
        var q = null
        var results = [];
        if (data.type !== null && data.location !== null && data.price !== null) {
          q = query(collection(db, "properties_detail"), where("approved", "==", true), where("type", "==", `${data.type}`), where("lga", "==", `${data.location}`), where("price", "<=", `${data.price}`)).withConverter(propConverter);
        } else if (data.location !== null && data.price !== null) {
          q = query(collection(db, "properties_detail"), where("approved", "==", true), where("lga", "==", `${data.location}`), where("price", "<=", `${data.price}`), orderBy("uploaded_at", "desc")).withConverter(propConverter);
        } else if (data.type !== null && data.price !== null) {
          q = query(collection(db, "properties_detail"), where("approved", "==", true), where("type", "==", `${data.type}`), where("price", "<=", `${data.price}`)).withConverter(propConverter);
        } else if (data.type !== null && data.location !== null) {
          q = query(collection(db, "properties_detail"), where("approved", "==", true), where("type", "==", `${data.type}`), where("lga", "==", `${data.location}`)).withConverter(propConverter);
        } else {
          q = query(collection(db, "properties_detail"), where("approved", "==", true)).withConverter(propConverter);
        }
        const querySnapshot = await getDocs(q);
        results = [];
        querySnapshot.forEach((doc) => {
          var element = doc.data();
          results.push(element);
        });

        var properties = results?.filter(e => {
          return (e.name + e.lga + e.state || '').toLowerCase().indexOf((data.query || '').toLowerCase()) > -1
        })
        commit('SET_PROPERTIES', properties);
        commit('SET_SEARCH_STATUS', true);
        
      },
  };