import { listPaths } from './util'
const routes = {
    index: {
        path: '/',
        name: 'index',
        component: () => import('@/components/home/HomePage.vue'),
        alias: '/index'
    },
    properties: {
        path: '/properties',
        name: 'properties',
        component: () => import('@/components/properties/PropertiesComponent.vue')
    },
    propertyDetail: {
        path: '/property-detail',
        name: 'property-detail',
        component: () => import('@/components/properties/PropertyComponent.vue')
    },
    login: {
        path: '/login',
        name: 'login',
        component: () => import('@/components/forms/LoginComponent.vue')
    },
    propertyLogin: {
        path: '/property/login',
        name: 'property-login',
        component: () => import('@/components/forms/PaymentLogin.vue')
    },
    checkout: {
        path: '/checkout',
        name: 'checkout',
        component: () => import('@/components/forms/CheckoutComponent.vue')
    },
    scheduleInspection: {
        path: '/schedule-inspection',
        name: 'schedule-inspection',
        component: () => import('@/components/forms/ScheduleInspection.vue')
    },
    paymentStatus: {
        path: '/payment-status',
        name: 'payment-status',
        component: () => import('@/components/notifications/CheckOutStatus.vue')
    },
    inspectionStatus: {
        path: '/inspection-status',
        name: 'inspection-status',
        component: () => import('@/components/notifications/InspectionStatus.vue')
    },
    passwordRecovery: {
        path: '/password-recovery',
        name: 'password-recovery',
        component: () => import('@/components/forms/password/PasswordRecovery.vue')
    },
    recoverySuccessful: {
        path: '/recovery-successful',
        name: 'recovery-successful',
        component: () => import('@/components/forms/password/RecoverySuccessful.vue')
    },
    partners: {
        path: '/partners',
        name: 'partners',
        component: () => import('@/components/forms/PartnersLogin.vue')
    },
    partnersRegister: {
        path: '/partners/register',
        name: 'partners-register',
        component: () => import('@/components/forms/RegisterIndividual.vue')
    },
    bavicAgent: {
        path: '/bavic-agent',
        name: 'bavic-agent',
        component: () => import('@/components/realtor/BavicAgent.vue')
    },
    register: {
        path: '/register',
        name: 'register',
        component: () => import('@/components/forms/SignUp.vue')
    },
    blogs: {
        path: '/blogs',
        name: 'blogs',
        component: () => import('@/components/blogs/BlogsComponent.vue')
    },
    blog: {
        path: '/blog',
        name: 'blog',
        component: () => import('@/components/blogs/BlogComponent.vue')
    },
    dashboard: {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/components/DashBoard.vue')
    },
    about: {
        path: '/about',
        name: 'about',
        component: () => import('@/components/about/AboutComponent.vue')
    },
    contactUs: {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('@/components/forms/ContactForm.vue')
    },
    helpCenter: {
        path: '/help-center',
        name: 'help-center',
        component: () => import('@/components/help/HelpCenter.vue')
    },
    terms: {
        path: '/terms',
        name: 'terms',
        component: () => import('@/components/legal/TermsAndCondition.vue')
    },
    privacy: {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/components/legal/PrivacyPolicy.vue')
    },
    paymentPolicy: {
        path: '/payment',
        name: 'payment-policy',
        component: () => import('@/components/legal/PaymentPolicy.vue')
    },
    partnership: {
        path: '/partnership',
        name: 'partnership',
        component: () => import('@/components/legal/PartnershipAgreement.vue')
    },
    error: {
        path: 'index/*',
        name: 'error',
        component: () => import('@/components/error/ErrorComponent.vue')
    },
    errorPage: {
        path: '/error',
        name: 'error-page',
        component: () => import('@/components/error/ErrorComponent.vue')
    }
}

const publicPaths = listPaths(routes)

const publicRoutes = Object.values(routes)

export { publicRoutes, publicPaths }