/**
 * Returns an array of objects containing route names as keys and their corresponding paths as values.
 *
 * @param {Object} routes - The object containing route names as keys and their corresponding paths as values.
 * @return {Array<Object>} An array of objects with route names as keys and their corresponding paths as values.
 */
export const listPaths = (routes) => {
    let result = {}
    const routeKeys = Object.keys(routes)
    for (let i = 0; i < routeKeys.length; i++) {
        let key = routeKeys[i]
        result[key] = routes[key].path
    }
    console.log(result)
    return result
}

