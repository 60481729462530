export const mutations = {
    'SET_PROPERTIES' (state, data){
        state.properties = data
    },
    'SET_PROPERTY' (state, data){
        state.property = data
    },
    'SET_SEARCH_STATUS' (state, data){
      state.searchStatus = data
  },
    'SET_TRENDING_PROPERTY' (state, data) {
        state.user.favourites.push(data);
    },
    'DELETE_TRENDING_PROPERTY' (state, element) {
        var arr = state.trendingProperties.trendingProperties;
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] == element ) {
            arr.splice(i, 1);
          }
        }
    },
  };
  