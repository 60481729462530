import Vue from 'vue'
import App from './App.vue'
import store from './store';
import vuetify from './plugins/vuetify'
import router from './router'
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBy3PG_XkAUm3jnQ2UauGJSTXKpUEMTvxc",
  authDomain: "bavic-app.firebaseapp.com",
  projectId: "bavic-app",
  storageBucket: "bavic-app.appspot.com",
  messagingSenderId: "212850267769",
  appId: "1:212850267769:web:b330f626797352956066a8",
  measurementId: "G-79YX83G0WV"
};
AOS.init();

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
const analytics = getAnalytics(app);
Vue.prototype.$db = db
Vue.prototype.$vue = Vue
Vue.prototype.$analytics = analytics

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
