export const getters = {
  searchStatus: state => {
        return state.searchStatus;
    },
    properties: state => {
        return state.properties;
    },
    getPropertiesName: state => {
        var returnArray = []
        for (let index = 0; index < state.properties.length; index++) {
          returnArray.push(state.properties[index].name);
        }
        return returnArray
      },
      getPropertiesLocation: state => {
        var returnArray = []
        for (let index = 0; index < state.properties.length; index++) {
          returnArray.push(state.properties[index].lga);
        }
        return returnArray
      },
      getPropertiesPrice: state => {
        var returnArray = []
        for (let index = 0; index < state.properties.length; index++) {
          returnArray.push(state.properties[index].price);
        }
        return returnArray
      },
      paginateProperties: (state) => (data) => {
        let from = (data.page * data.perPage) - data.perPage;
        let to = (data.page * data.perPage);
        return state.properties.slice(from, to);
      },
      setPages: (state) => (data) => {
        let numberOfPages = Math.ceil(state.properties.length / data.perPage);
        let pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          pages.push(index);
        }
        return pages;
      },
  };
