export class ImageUrl {
    constructor (url) {
        this.url = url;
    }
    getPath() {
      const baseUrl = "https://firebasestorage.googleapis.com/v0/b/bavic-app.appspot.com/o/";
      let imagePath = this.url.replace(baseUrl,"");
      const indexOfEndPath = imagePath.indexOf("?");
      imagePath = imagePath.substring(0,indexOfEndPath);
      // imagePath = imagePath.replace(/%2F/g,"/");
      imagePath = decodeURIComponent(imagePath);


      return imagePath;
  }
}

export class Property {
    constructor (name, state, lga, cover_image, id, type, date ) {
        this.name = name;
        this.state = state;
        this.lga = lga;
        this.cover_image = cover_image;
        this.id = id;
        this.type = type;
        this.date = date
    }
}
// Firestore data converter
export const objConverter = {
    toFirestore: (obj) => {
        return {
            name: obj.name,
            state: obj.state,
            lga: obj.lga,
            cover_image: obj.cover_image,
            id: obj.ref,
            type: obj.type,
            date: obj.uploaded_at
            };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Property(data.name, data.state, data.lga, data.cover_image, data.ref, data.type, data.uploaded_at);
    }
  };
    export class PropertyCard {
        constructor (name, state, lga, cover_image, id, type, theme_color, price ) {
            this.name = name;
            this.state = state;
            this.lga = lga;
            this.cover_image = cover_image;
            this.id = id;
            this.type = type;
            this.theme_color = theme_color
            this.price = price
        }
    }
    // Firestore data converter
    export const propConverter = {
        toFirestore: (obj) => {
            return {
                name: obj.name,
                state: obj.state,
                lga: obj.lga,
                cover_image: obj.cover_image,
                id: obj.ref,
                type: obj.type,
                theme_color: obj.theme_color,
                price: obj.price,
                };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new PropertyCard(data.name, data.state, data.lga, data.cover_image, data.ref, data.type, data.theme_color, data.price);
        }
    };
export class Member {
    constructor (email, firstname, lastname, profile_pic, partnership_type, role, date, ref ) {
        this.email = email;
        this.firstname = firstname;
        this.lastname = lastname;
        this.profile_pic = profile_pic;
        this.partnership_type = partnership_type;
        this.role = role;
        this.date = date;
        this.id = ref;
    }
}
// Firestore data converter
export const memObjConverter = {
    toFirestore: (obj) => {
        return {
            email: obj.email,
            firstname: obj.firstname,
            lastname: obj.lastname,
            profile_pic: obj.profile_pic,
            partnership_type: obj.partnership_type,
            role: obj.role,
            date: obj.uploaded_at,
            id: obj.ref,
            };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Member(data.email, data.firstname, data.lastname, data.profile_pic, data.partnership_type, data.role, data.uploaded_at, data.ref);
    }
};

export class StaffList {
    constructor (firstname, lastname, profile_pic, ref, role, email) {
        this.name = firstname + ' ' + lastname;
        this.profile_pic = profile_pic;
        this.id = ref;
        this.role = role;
        this.email = email;
    }
    fullName() {
        return this.firstname + ' ' + this.lastname;
    }
}
// Firestore data converter
export const staffObjConverter = {
    toFirestore: (obj) => {
        return {
            firstname: obj.firstname,
            lastname: obj.lastname,
            profile_pic: obj.profile_pic,
            id: obj.ref,
            role: obj.role,
            email: obj.email
            };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new StaffList(data.firstname, data.lastname, data.profile_pic, data.ref, data.role, data.email);
    }
};

export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
