const state = {
  isLoggedIn: '',
  user: {},
  property: {},
};
const getters = {
  isLoggedIn: state => {
      return state.isLoggedIn;
  },
  getUser: state => {
      return state.user;
  },
  getName: state => {
      return state.user.firstname + ' ' + state.user.lastname;
  },
  getProfile_pic: state => {
      return state.profile_pic;
  },
  getEmail: state => {
      return state.user.email;
  },
  getUId: state => {
      return state.user.ref;
  },
  getRole: state => {
      return state.user.role;
  },
  getProperty: state => {
      return state.property;
  },
  getFave: state => {
      return state.user.favourites;
  },
};
const mutations = {
  'SET_lOGGED_IN_STATUS' (state, status) {
      state.isLoggedIn = status;
  },
  'SET_USER' (state, status) {
      state.user = status;
  },
  'CLEAR_USER' (state) {
      state.user = {};
  },
  'SET_PROPERTY' (state, data) {
      state.property = data;
  },
  'SET_FAVOURITES' (state, element) {
      state.user.favourites.push(element);
  },
  'DELETE_FAVOURITES' (state, element) {
      var arr = state.user.favourites;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == element ) {
          arr.splice(i, 1);
        }
      }
  }
};
const actions = {
  setStatus ({commit}, status) {
      commit('SET_lOGGED_IN_STATUS', status);
  },
  setUser ({commit}, status) {
      commit('SET_USER', status);
  },
  clearUser ({commit}, status) {
      commit('CLEAR_USER', status);
  },
  setProperty ({commit}, data) {
      commit('SET_PROPERTY', data);
  },
  deleteFavourite ({commit}, element) {
      commit('DELETE_FAVOURITES', element);
  },
  setFavourite ({commit}, element) {
      commit('SET_FAVOURITES', element);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
